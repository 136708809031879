<template>
  <VRow class="chat-container-wrapper">
    <VNavigationDrawer
      v-model="drawer"
      clipped
      :width="$vuetify.breakpoint.smAndDown ? '100%' : drawerWidth"
      :class="{
        'xs12': threadsOnly,
        'xs11 md6': !threadsOnly && permanentThreads,
        'col no-drawer-border fill-height px-0': true
      }"
      :style="{
        padding: '0px',
        border: 'none',
        'background-color': 'transparent',
        'border-right-color': 'transparent',
        paddingRight: $vuetify.breakpoint.mdAndUp ? '15px': 'auto',
        marginTop: '2px',
        flex: $vuetify.breakpoint.mdAndUp ? '0 1 350px' : '100%'
      }"
      :fixed="fixedThreads"
      :permanent="permanentThreads"
      :floating="floatingThreads"
    >
      <Threads
        ref="threads"
        :current-thread="thread"
        :background-color="$vuetify.breakpoint.smAndDown ? 'white' : 'transparent'"
        :channels="[`chat.${$user.id}`]"
        @threadsReady="onThreadsReady"
        @loadMessages="onSetCurrentThread"
        @startChat="onHandleCreate"
        @toggleDrawer="onToggleDrawer"
      />
    </VNavigationDrawer>
    <!-- .b__messanger-dialogs -->
    <VCol
      v-if="threadsOnly !== true"
      fill-height
      class="px-0 py-0"
      :style="{
        maxWidth: $vuetify.breakpoint.smAndDown ? '100%' : 'calc(100% - 350px)'
      }"
      :grow="!permanentThreads"
    >
      <div
        v-if="isFirstLoad"
        :style="{
          left: $vuetify.breakpoint.smAndDown ? '0' : '350px'
        }"
      />
      <Messages
        v-else-if="mode == 'replying'"
        :thread-drawer-active="!permanentThreads"
        :old-thread="oldThread"
        :thread="thread"
        :pusher="pusher"
        @updateThread="onUpdateThread"
        @updateThreadTimestamp="onUpdateThreadTimestamp"
        @closeThread="onCloseThread"
        @toggleDrawer="onToggleDrawer"
        @messagesLoaded="onUpdateThreadUnreadCount"
      />
      <StartChat
        v-else
        force-group
        :dismissible="hasThread"
        :parent-is-saving="isSaving"
        @toggle="onToggleMode"
        @startThread="onNewThread"
        @toggleDrawer="onToggleDrawer"
      />
    </VCol>
  </VRow>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Threads from "./Threads";
import Messages from "./Messages";
import StartChat from "./StartChat";

export default {
  name: "Chat",
  components: {
    Threads,
    Messages,
    StartChat,
  },
  props: {
    threadId: {
      type: String,
      default: null,
    },
    threadsOnly: {
      type: Boolean,
      default: false,
    },
    forceVisibleDrawer: {
      type: Boolean,
      default: false,
    },
    floatingThreads: {
      type: Boolean,
      default: true,
    },
    drawerWidth: {
      type: String,
      default: "300px",
    },
  },
  data() {
    return {
      drawer: false,
      mode: "creating",
      isSaving: false,
      isFirstLoad: true,
      newMessageCount: 0,
      oldThread: null,
      tabs: {
        index: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      thread: "chat/currentThread",
    }),
    pusher: function () {
      return this.$echo;
    },
    peer: function () {
      let peer;
      if (this.user && this.thread) {
        if (this.$user.id == this.thread.user_id) {
          peer = this.thread.to;
        } else if (this.$user.id == this.thread.to_id) {
          peer = this.thread.user;
        } else {
          peer = this.user;
        }
        return peer || this.user;
      }
      return null;
    },
    hasThread() {
      return this.thread instanceof Object;
    },
    permanentThreads() {
      if (this.floatingThreads === false) {
        return true;
      }
      return this.$vuetify.breakpoint.mdAndUp;
    },
    fixedThreads() {
      if (this.floatingThreads === false) {
        return false;
      }
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  created() {
    this.doSetHuddleMode(false);
    this.$log.debug("[Chat: Index]: Component mounted.");
    const threadId = this.threadId || this.$route.params.id;
    if (threadId == "public") {
      /**
       * Get last thread from session storage
       */
      let thread = window.localStorage.getItem("lastThread");
      if (thread) {
        thread = JSON.parse(thread);
        this.$log.debug("[Chat: Index]: Found last thread", thread);
        this.onSetCurrentThread(thread);
      } else {
        this.$log.debug(
          "[Chat: Index]: No thread specified, defaulting to:",
          threadId
        );
        this.getThread(threadId);
      }
    } else if (threadId) {
      this.$log.debug("[Chat: Index]: Found non public thread", threadId);
      this.getThread(threadId);
    } else {
      this.$log.debug("[Chat: Index]: Not loading any threads");
    }

    this.$nextTick(() => {
      if (this.forceVisibleDrawer) {
        this.drawer = true;
      }
    });
  },
  methods: {
    ...mapActions({
      doGetThread: "chat/getThread",
      doSetThread: "chat/setThread",
      doUpdateThread: "chat/updateThread",
      doSaveThread: "chat/saveThread",
      doSaveGroupThread: "chat/saveGroupThread",
      doUpdateCurrentThread: "chat/updateThread",
      doSetHuddleMode: "chat/setHuddleMode",
    }),
    onThreadsReady() {
      this.isFirstLoad = false;
    },
    onSetCurrentThread(thread) {
      this.mode = "replying";
      if (!this.thread || (this.thread && thread.id != this.thread.id)) {
        this.$log.debug(
          "[Chat: Index]: Setting current thread...[" + thread.title + "]"
        );
        this.oldThread = { ...thread };
        this.doSetThread(thread).then((thread) => {
          window.localStorage.setItem("lastThread", JSON.stringify(thread));
          this.$emit("threadChanged");
        });
      } else {
        this.$log.debug(
          "[Chat: Index]: Not setting current thread...[" + thread.title + "]"
        );
      }
    },
    onSetCurrentGroupThread(thread) {
      this.onSetCurrentThread(thread);
    },
    onSetCurrentThreadAutomatically(thread) {
      if (!thread || (thread && !thread.id)) {
        this.mode = "creating";
        return;
      }
      // if (thread.group_id !== null) {
      //   this.tabs.index = 1;
      //   this.setCurrentGroupThread(thread);
      // } else {
      //   this.tabs.index = 0;
      //   this.onSetCurrentThread(thread);
      // }
      this.onSetCurrentThread(thread);
    },
    onUpdateThread(properties) {
      this.doUpdateCurrentThread(properties);
    },
    onUpdateThreadUnreadCount() {
      const thread = this.oldThread || { new_messages_for_user_count: 0 };
      const threadCount = thread.new_messages_for_user_count || 0;
      const count =
        (this.thread.new_messages_for_user_count || 0) - threadCount;
      this.doUpdateThread({
        new_messages_for_user_count: count < 0 ? 0 : count,
      });
      console.log(
        "New Messages Count Before",
        threadCount,
        this.$user.new_messages_count
      );
      this.$user.new_messages_count -= thread.new_messages_for_user_count;
      this.$user.new_messages_count =
        this.$user.new_messages_count < 0 ? 0 : this.$user.new_messages_count;
      console.log(
        "New Messages Count After",
        threadCount,
        this.$user.new_messages_count
      );
    },
    onUpdateThreadTimestamp(id) {
      const index = this.$refs.threads.threads.data.findIndex(
        (t) => t.id === id
      );
      if (index > -1) {
        const thread = this.$refs.threads.threads.data[index];
        this.$refs.threads.threads.data.splice(index, 1, {
          ...thread,
          ...{ updated_at: new Date() },
        });
        this.$refs.threads.scrollToSelectedThread(thread.thread_id);
      }
    },
    onUpdateGroupThread(properties) {
      Object.keys(properties).map((property) => {
        this.groupThread[property] = properties[property];
      });
      this.$refs.groupThreads.updateThread(this.groupThread.id, properties);
    },
    async getThread(id) {
      this.$log.debug("[Chat: Index]: Loading thread", id);
      if (this.$refs.hasOwnProperty("threads")) {
        const currentThread = this.$refs.threads.findLocalThread(id);
        if (currentThread) {
          this.onSetCurrentThreadAutomatically(currentThread);
          return currentThread;
        }
      }
      return this.doGetThread(id)
        .then((response) => {
          this.onSetCurrentThreadAutomatically(response.data);
          Promise.resolve(response.data);
        })
        .catch((e) => this.$log.error(e));
    },
    updatePeer(peer) {
      this.peer = peer || this.user;
    },
    onHandleCreate() {
      // If we're not on the chat route then navigate to the chat page
      this.drawer = false;
      if (this.$vuetify.breakpoint.mdAndUp) {
        if (this.$route.name == "chat.index") {
          this.$log.debug("Chat: Changing mode", this.mode);
          this.mode =
            this.mode == "creating"
              ? this.thread
                ? "replying"
                : "creating"
              : "creating";
        } else {
          this.navigateToChat();
        }
      } else {
        this.navigateToChat();
      }
    },
    navigateToChat() {
      this.$log.debug(
        "Chat: Navigating to chat index because we're on",
        this.$route.name
      );
      this.$router.push({
        name: "chat.index",
        id: this.thread ? this.thread.id : null,
      });
    },
    onNewThread(thread) {
      const existing = this.$refs.threads.getLocalThread(thread);
      if (!existing) {
        this.$log.debug("Chat: Creating thread", thread);
        this.doSaveThread(thread)
          .then((thread) => {
            if (thread.status != "error") {
              this.$log.debug("Chat: Created thread", thread);
              // Add the thread to the threads components
              // if (this.$refs.threads) this.$refs.threads.addThread(thread);
              this.onSetCurrentThread(thread);
              this.mode = "replying";
              if (this.$route.name !== "chat.index") {
                this.navigateToChat();
              }
            } else {
              this.$log.debug("Chat: Create thread error", thread);
              this.errors = thread;
            }
          })
          .catch((error) => {
            this.error = error;
          });
      } else {
        this.$log.debug("Chat: Loading existing thread", existing.thread_id);
        this.onSetCurrentThread(existing);
      }
    },
    onCloseThread() {
      this.$log.debug("Chat: Closing current thread", this.thread);
      this.doSetThread(null);
      this.mode = "creating";
    },
    onToggleDrawer() {
      this.drawer = !this.drawer;
    },
    onToggleMode() {
      this.mode = this.mode === "creating" ? "replying" : "creating";
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/variables.scss";
.chat-container-wrapper {
  .v-navigation-drawer.no-drawer-border {
    padding-right: 8px;
    .v-navigation-drawer__border {
      display: none;
    }
  }

  .v-tabs.chat-container {
    display: flex;
    flex-direction: column;
    padding-top: 1px;
  }

  .v-tabs.chat-container .v-tabs__items,
  .v-tabs.chat-container .v-tabs__items .v-tabs__content,
  .v-tabs.chat-container .v-window {
    flex: 1 1 auto;
    overflow: hidden;
  }

  .v-tabs.chat-container .v-window .v-window__container {
    height: calc(100% + 10px);
  }

  .v-tabs__container--grow .v-tabs__div.add-tab {
    flex: 0 0 64px;
    max-width: 64px;
    height: 48px;
  }

  .v-tabs__container--grow .v-tabs__div.add-tab .v-tabs__item {
    width: 48px;
    height: 48px;
  }

  .v-tabs__div.with-chip .v-tabs__item {
    justify-content: space-between;
    margin-left: 10px;
  }

  .v-tabs__div.with-chip .v-tabs__item span {
    color: $content-title-color;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 14px;
  }

  .v-chip.chat-chip {
    text-align: center;
    max-width: 22px;
    height: 16px;
    border-radius: 3px;
    background: $primary-color !important;
    padding: 4px;
  }

  .v-chip.chat-chip .v-chip__content {
    padding: 0;
    color: $white-color;
  }
}
</style>

<style scoped lang="scss">
@import "../../styles/variables.scss";

.chat-container-wrapper {
  height: 100%;
  overflow: hidden;
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{staticClass:"chat-container-wrapper"},[_c('VNavigationDrawer',{class:{
      'xs12': _vm.threadsOnly,
      'xs11 md6': !_vm.threadsOnly && _vm.permanentThreads,
      'col no-drawer-border fill-height px-0': true
    },style:({
      padding: '0px',
      border: 'none',
      'background-color': 'transparent',
      'border-right-color': 'transparent',
      paddingRight: _vm.$vuetify.breakpoint.mdAndUp ? '15px': 'auto',
      marginTop: '2px',
      flex: _vm.$vuetify.breakpoint.mdAndUp ? '0 1 350px' : '100%'
    }),attrs:{"clipped":"","width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : _vm.drawerWidth,"fixed":_vm.fixedThreads,"permanent":_vm.permanentThreads,"floating":_vm.floatingThreads},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('Threads',{ref:"threads",attrs:{"current-thread":_vm.thread,"background-color":_vm.$vuetify.breakpoint.smAndDown ? 'white' : 'transparent',"channels":[`chat.${_vm.$user.id}`]},on:{"threadsReady":_vm.onThreadsReady,"loadMessages":_vm.onSetCurrentThread,"startChat":_vm.onHandleCreate,"toggleDrawer":_vm.onToggleDrawer}})],1),(_vm.threadsOnly !== true)?_c('VCol',{staticClass:"px-0 py-0",style:({
      maxWidth: _vm.$vuetify.breakpoint.smAndDown ? '100%' : 'calc(100% - 350px)'
    }),attrs:{"fill-height":"","grow":!_vm.permanentThreads}},[(_vm.isFirstLoad)?_c('div',{style:({
        left: _vm.$vuetify.breakpoint.smAndDown ? '0' : '350px'
      })}):(_vm.mode == 'replying')?_c('Messages',{attrs:{"thread-drawer-active":!_vm.permanentThreads,"old-thread":_vm.oldThread,"thread":_vm.thread,"pusher":_vm.pusher},on:{"updateThread":_vm.onUpdateThread,"updateThreadTimestamp":_vm.onUpdateThreadTimestamp,"closeThread":_vm.onCloseThread,"toggleDrawer":_vm.onToggleDrawer,"messagesLoaded":_vm.onUpdateThreadUnreadCount}}):_c('StartChat',{attrs:{"force-group":"","dismissible":_vm.hasThread,"parent-is-saving":_vm.isSaving},on:{"toggle":_vm.onToggleMode,"startThread":_vm.onNewThread,"toggleDrawer":_vm.onToggleDrawer}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }